import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import Upload from './components/Upload';
import Query from './components/Query';
import Login from './components/Login';
import { SERVER_URL } from './Constants';

// Protected Route component
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
	const [isValidating, setIsValidating] = useState(true);
	const [isValid, setIsValid] = useState(false);

	const accessToken = localStorage.getItem('accessToken');

	useEffect(() => {
		const validateToken = async () => {
			if (!accessToken) {
				setIsValidating(false);
				return;
			}

			try {
				const response = await fetch(`${SERVER_URL}/check_token`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${accessToken}`,
					},
				});

				if (response.ok) {
					setIsValid(true);
				} else {
					// If token validation fails, remove the invalid token
					localStorage.removeItem('accessToken');
				}
			} catch (error) {
				console.error('Token validation error:', error);
				localStorage.removeItem('accessToken');
			} finally {
				setIsValidating(false);
			}
		};

		validateToken();
	}, [accessToken]);

	// Show loading state while validating
	if (isValidating) {
		return <div>Loading...</div>; // You can replace this with a proper loading component
	}

	// Redirect to login if no token or invalid token
	if (!accessToken || !isValid) {
		return <Navigate to="/login" replace />;
	}

	return <>{children}</>;
};

function App() {
	return (
		<Router basename="/">
			<div className="App">
				<Routes>
					{/* Public route */}
					<Route path="/login" element={<Login />} />

					{/* Protected routes */}
					<Route
						path="/"
						element={
							<ProtectedRoute>
								<Query />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/upload"
						element={
							<ProtectedRoute>
								<Upload />
							</ProtectedRoute>
						}
					/>

					{/* Catch all route - redirect to main page */}
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;