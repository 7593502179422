interface ImageModalProps {
	selectedImage: string | null;
	setSelectedImage: (value: string | null) => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ selectedImage, setSelectedImage }) => {
	if (!selectedImage) return null;

	return (
		<div
			className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
			onClick={() => setSelectedImage(null)}
		>
			<div className="max-w-4xl w-full h-[90vh] relative">
				<img
					src={selectedImage}
					alt="Expanded view"
					className="w-full h-full object-contain rounded-lg"
				/>
				<button
					className="absolute top-4 right-4 text-white hover:text-gray-300"
					onClick={() => setSelectedImage(null)}
				>
					<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</button>
			</div>
		</div>
	);
};

export default ImageModal;