import { useState, useEffect } from 'react';
import { FaPaperPlane, FaSpinner, FaCloudUploadAlt } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../Constants';
import ErrorMessage from './ErrorMessage';
import ReactMarkdown from 'react-markdown'
import Confetti from 'react-confetti';
import ImageModal from './ImageModal';

interface Source {
	text: string;
	image_sources: string[];
}

interface Results {
	response: string;
	sources: Source[];
}





function Query() {
	const [slideshowIndex, setSlideshowIndex] = useState<number>(0);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const [question, setQuestion] = useState('');
	const [results, setResults] = useState<Results | null>(null);
	const [imageContentToggle, setImageContentToggle] = useState<boolean[]>([]);


	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const [showBirthdayMessage, setShowBirthdayMessage] = useState(false);

	const [shownConfetti, setShownConfetti] = useState(false);
	const [showConfetti, setShowConfetti] = useState(false);
	const [windowDimensions, setWindowDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	// Add this useEffect for window dimensions
	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);


	const isBirthdayPeriod = () => {
		const today = new Date();
		const year = today.getFullYear();
		const month = today.getMonth(); // 0-based, so November is 10
		const day = today.getDate();
		return month === 10 && (day === 23 || (day === 24 && year === 2024));
	};


	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const response = await fetch(`${SERVER_URL}/query_chunks`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${localStorage.getItem('accessToken')}`
				},
				body: JSON.stringify({ text: question })
			});

			if (!response.ok) {
				throw new Error(`Processing failed: ${response.statusText}`);
			}

			const data = await response.json();
			setResults(data);
			setImageContentToggle(new Array(data.sources.length).fill(false));

		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to process text');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="min-h-screen bg-gradient-to-br from-purple-50 to-white py-12 px-4">
			{showConfetti && isBirthdayPeriod() && (
				<Confetti
					width={windowDimensions.width}
					height={windowDimensions.height}
					numberOfPieces={150} // reduced from 200
					recycle={false}
					gravity={0.3} // added for slower falling
					tweenDuration={5000} // slower confetti animation
					onConfettiComplete={() => {
						setTimeout(() => setShowConfetti(false), 5000);
					}}
					style={{ position: 'fixed', zIndex: 1000 }} // explicit z-index
				/>
			)}

			{/* Birthday Message */}
			<AnimatePresence>
				{showBirthdayMessage && (
					<motion.div
						initial={{ opacity: 0, scale: 0.8 }} // changed from 0.5 for subtler scaling
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.8 }}
						className="fixed inset-0 flex items-center justify-center z-50 bg-black/30 backdrop-blur-sm" // reduced opacity
					>
						<motion.div
							initial={{ rotate: -5 }} // reduced initial rotation
							animate={{ rotate: [0, -2, 2, -2, 0] }} // reduced rotation angles
							transition={{ duration: 0.8, repeat: 1 }} // reduced duration and repeats
							className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 p-2 rounded-3xl"
						>
							<div className="bg-white rounded-2xl px-16 py-12 text-center">
								<h1 className="text-6xl font-bold bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 bg-clip-text text-transparent">
									Happy Birthday Maman! 🎂
								</h1>
								<p className="mt-4 text-2xl text-gray-600">
									With all our love! ❤️
								</p>
							</div>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
			{/* Conditionally render the birthday button */}
			{isBirthdayPeriod() && !shownConfetti && (
				<motion.button
					onClick={() => {
						setShowConfetti(true);
						setShownConfetti(true);
						setShowBirthdayMessage(true);
						setTimeout(() => setShowBirthdayMessage(false), 5000);
					}}
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					className="fixed top-4 right-4 bg-gradient-to-r from-pink-500 to-purple-500 text-white px-6 py-3 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 font-bold z-50 animate-bounce"
				>
					<span className="relative">
						<span className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full animate-ping"></span>
						<span className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full"></span>
						Click here for a surprise! ✨
					</span>
				</motion.button>
			)}

			{error && <ErrorMessage message={error} />}

			<motion.div
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				className="max-w-5xl mx-auto bg-white rounded-2xl shadow-xl p-6 sm:p-8"
			>
				{/* Header Section */}
				<div className="flex flex-col sm:flex-row justify-between items-center gap-6 mb-10">
					<motion.div
						initial={{ opacity: 0, x: -20 }}
						animate={{ opacity: 1, x: 0 }}
						className="text-center sm:text-left"
					>
						<h2 className="text-3xl sm:text-4xl font-bold bg-gradient-to-r from-purple-600 to-purple-400 bg-clip-text text-transparent mb-3">
							Ask Me Anything
						</h2>
						<p className="text-gray-600 text-lg">What would you like to know?</p>
					</motion.div>

					<Link to="/upload">
						<motion.button
							whileHover={{ scale: 1.02 }}
							whileTap={{ scale: 0.98 }}
							className="bg-gradient-to-r from-purple-600 to-purple-500 text-white px-8 py-3 rounded-xl flex items-center gap-3 hover:shadow-lg transition-all duration-300 font-medium"
						>
							<FaCloudUploadAlt className="text-xl" />
							Upload Content
						</motion.button>
					</Link>
				</div>

				{/* Form Section */}
				<motion.form
					onSubmit={handleSubmit}
					className="mb-12"
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ delay: 0.2 }}
				>
					<div className="relative bg-gray-50 rounded-2xl p-2">
						<textarea
							value={question}
							onChange={(e) => setQuestion(e.target.value)}
							className="w-full p-4 rounded-xl focus:ring-2 focus:ring-purple-300 transition-all min-h-[160px] text-gray-700 resize-none bg-transparent border-none outline-none text-lg"
							placeholder="Type your question here..."
						/>
						<motion.button
							type="submit"
							disabled={!question.trim() || isLoading}
							whileHover={{ scale: 1.02 }}
							whileTap={{ scale: 0.98 }}
							className={`absolute bottom-5 right-3 px-6 py-3 rounded-xl ${question.trim() && !isLoading
								? 'bg-gradient-to-r from-purple-600 to-purple-500 hover:shadow-lg'
								: 'bg-purple-300'
								} text-white transition-all duration-300 flex items-center gap-3 font-medium`}
						>
							{isLoading ? (
								<>
									<FaSpinner className="animate-spin text-lg" />
									<span>Thinking...</span>
								</>
							) : (
								<>
									<FaPaperPlane className="text-lg" />
									<span>Submit</span>
								</>
							)}
						</motion.button>
					</div>
				</motion.form>

				{/* Results Section */}
				<AnimatePresence>
					{results && (
						<motion.div
							className="space-y-8"
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -20 }}
							transition={{ duration: 0.3 }}
						>
							<motion.div
								className="bg-gray-50 p-8 rounded-2xl hover:shadow-lg transition-all duration-300"
								whileHover={{ y: -2 }}
							>
								<h3 className="text-2xl font-semibold text-purple-600 mb-6">Answer</h3>
								<ReactMarkdown className="text-gray-700 leading-relaxed text-lg">
									{results.response}
								</ReactMarkdown>
							</motion.div>

							{results.sources && results.sources.length > 0 && (
								<motion.div
									className="bg-gray-50 p-8 rounded-2xl hover:shadow-lg transition-all duration-300"
									whileHover={{ y: -2 }}
								>
									<h3 className="text-2xl font-semibold text-purple-600 mb-6 flex items-center gap-3">
										<IoDocumentTextOutline className="text-2xl" />
										Sources
									</h3>
									<div className="grid gap-6">
										{results.sources.map((source, index) => (
											<motion.div
												key={index}
												initial={{ opacity: 0, y: 20 }}
												animate={{ opacity: 1, y: 0 }}
												transition={{ delay: index * 0.1 }}
												className="bg-white rounded-xl hover:shadow-md transition-all duration-300"
											>
												<div className="flex flex-col lg:flex-row">
													{imageContentToggle[index] ? (


														// Then replace your image section with this:
														<div className="relative w-full max-w-2xl mx-auto"> {/* Container with max width */}
															{source.image_sources.length > 0 && (
																<div className="relative aspect-square rounded-xl overflow-hidden shadow-lg">
																	{/* Image container */}
																	<div className="relative w-full h-full">
																		<motion.img
																			src={source.image_sources[slideshowIndex]}
																			alt={`Source ${index + 1}`}
																			className="w-full h-full object-cover"
																			whileHover={{ scale: 1.01 }}
																			onClick={() => setSelectedImage(source.image_sources[slideshowIndex])}
																		/>

																		{/* Navigation arrows */}
																		{source.image_sources.length > 1 && (
																			<>
																				<button
																					onClick={(e) => {
																						e.stopPropagation();
																						setSlideshowIndex(prev => {
																							return prev > 0 ? prev - 1 : source.image_sources.length - 1
																						});
																					}}
																					className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-md transition-all duration-200"
																				>
																					<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
																						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
																					</svg>
																				</button>
																				<button
																					onClick={(e) => {
																						e.stopPropagation();
																						setSlideshowIndex(prev => {
																							return prev < source.image_sources.length - 1 ? prev + 1 : 0
																						});
																					}}
																					className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-md transition-all duration-200"
																				>
																					<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
																						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
																					</svg>
																				</button>
																			</>
																		)}

																		{/* Image counter */}
																		{source.image_sources.length > 1 && (
																			<div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-black/50 text-white px-3 py-1 rounded-full text-sm">
																				{(slideshowIndex || 0) + 1} / {source.image_sources.length}
																			</div>
																		)}
																	</div>
																</div>
															)}

															{/* View Text button */}
															<button
																onClick={() => {
																	const newToggleState = [...imageContentToggle];
																	newToggleState[index] = !newToggleState[index];
																	setImageContentToggle(newToggleState);
																}}
																className="absolute top-4 right-4 bg-white/90 backdrop-blur-sm px-3 py-1 rounded-full text-sm font-medium shadow-sm hover:bg-white transition-colors duration-200"
															>
																View Text
															</button>
														</div>
													) : (
														// Text Section
														<div className="p-6">
															<div className="relative">
																{source.image_sources.length > 0 && (
																	<button
																		onClick={() => {
																			const newToggleState = [...imageContentToggle];
																			newToggleState[index] = !newToggleState[index];
																			setImageContentToggle(newToggleState);
																		}}
																		className="absolute top-4 right-4 bg-white/90 backdrop-blur-sm px-3 py-1 rounded-full text-sm font-medium shadow-sm hover:bg-white transition-colors duration-200"
																	>
																		View Image
																	</button>
																)}
																<h4 className="text-lg font-medium mb-4 mt-4 text-gray-700">Relevant Text:</h4>
																<ReactMarkdown className="text-gray-600 p-3 bg-gray-50 rounded-lg">
																	{source.text}
																</ReactMarkdown>
															</div>
														</div>
													)}
												</div>
											</motion.div>
										))}
									</div>
								</motion.div>
							)}
						</motion.div>
					)}
				</AnimatePresence>
			</motion.div>
			<ImageModal selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
		</div>
	);
}
export default Query;