import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUploadCloud, FiImage, FiFileText, FiCheck, FiEdit2 } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { SERVER_URL } from '../Constants';
import ImageModal from './ImageModal';
import { Link } from 'react-router-dom';
import ErrorMessage from './ErrorMessage';


const prompts = [
	"Please transcribe every single piece of text visible in this image, including small print, numbers, labels, and any text regardless of size or position. List everything exactly as written.",

	"Perform a detailed text extraction of this image. Start from the top-left corner and work your way across and down, transcribing every character, number, and word you see. Don't skip anything, no matter how minor it seems.",

	"I need a complete, exhaustive transcription of all text elements in this image. Include everything - main text, captions, watermarks, logos, footnotes, and any visible writing. Be thorough and precise.",

	"Working systematically through this image, transcribe ALL text content: 1) Main text 2) Headers 3) Subtext 4) Numbers 5) Labels 6) Watermarks 7) Small print 8) Any other visible text. Leave nothing out.",

	"Conduct a meticulous, line-by-line transcription of every text element in this image. Imagine you're a scanner that must capture every single character. Include all text regardless of font size, color, or position."
]

function Upload() {
	const [imageContentToggle, setImageContentToggle] = useState<boolean[]>([]);
	const [isProcessing, setIsProcessing] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const [uploadType, setUploadType] = useState<'image' | 'text' | null>(null);
	const [images, setImages] = useState<File[]>([]);
	const [ocrData, setOcrData] = useState<any[]>([]);
	const [textInput, setTextInput] = useState<string>('');
	const [chunks, setChunks] = useState<any>(null);
	const [step, setStep] = useState(1);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [captionIndexes, setCaptionIndexes] = useState<number[]>([]);

	const onDrop = useCallback((acceptedFiles: File[]) => {
		setImages(acceptedFiles);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			'image/*': ['.jpeg', '.jpg', '.png']
		}
	});

	const handleOCRProcess = async () => {
		setIsProcessing(true);
		setError(null);

		try {
			const formData = new FormData();
			images.forEach((file, index) => {
				formData.append(`images`, file, `image_${index + 1}.png`);
			});

			const response = await fetch(`${SERVER_URL}/upload`, {
				method: 'POST',
				headers: {
					"Authorization": `Bearer ${localStorage.getItem('accessToken')}`
				},
				body: formData
			});

			if (!response.ok) {
				throw new Error(`Upload failed: ${response.statusText}`);
			}

			const data = await response.json();
			setOcrData(data);
			setCaptionIndexes(Array(data.length).fill(0));
			setStep(3);
		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to process images');
		} finally {
			setIsProcessing(false);
		}
	};


	const handleRecaption = async (index: number) => {
		setIsProcessing(true);
		setError(null);

		const currentIndex = captionIndexes[index];

		try {
			const formData = new FormData();
			formData.append('image', images[index]);
			formData.append('prompt', prompts[currentIndex]);

			const response = await fetch(`${SERVER_URL}/caption`, {
				method: 'POST',
				headers: {
					"Authorization": `Bearer ${localStorage.getItem('accessToken')}`
				},
				body: formData
			});

			if (!response.ok) {
				throw new Error(`Captioning failed: ${response.statusText}`);
			}

			const data = await response.json();
			const newTexts = [...ocrData];
			newTexts[index].content = data.content;
			setOcrData(newTexts);

			setCaptionIndexes(prev => {
				const newIndexes = [...prev];
				newIndexes[index] = currentIndex + 1;
				return newIndexes;
			});



		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to recaption image');
		} finally {
			setIsProcessing(false);
		}
	}

	const handleTextConfirmation = async () => {
		setIsProcessing(true);
		setError(null);

		try {
			const response = await fetch(`${SERVER_URL}/convert_to_chunks`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${localStorage.getItem('accessToken')}`
				},
				body: JSON.stringify({ texts: ocrData })
			});

			if (!response.ok) {
				throw new Error(`Processing failed: ${response.statusText}`);
			}

			const data = await response.json();
			setChunks(data);

			setStep(5);
		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to process text');
		} finally {
			setIsProcessing(false);
		}
	};

	const handleFinalSubmission = async () => {
		// Mock final submission
		setIsProcessing(true);
		setError(null);

		try {
			const response = await fetch(`${SERVER_URL}/submit_chunks`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${localStorage.getItem('accessToken')}`
				},
				body: JSON.stringify({ chunks })
			});

			if (!response.ok) {
				throw new Error(`Submission failed: ${response.statusText}`);
			}

			setStep(1);
			setImages([]);
			setOcrData([]);
			setTextInput('');
			setChunks([]);
			setImageContentToggle([]);
		} catch (err) {
			setError(err instanceof Error ? err.message : 'Failed to submit chunks');
		} finally {
			setIsProcessing(false);
		}
	};




	return (
		<div className="min-h-screen bg-gradient-to-br from-pink-50 to-purple-50 p-8">

			<div className="max-w-4xl mx-auto">
				<Link
					to="/query"
					className="inline-block mb-6 text-purple-600 hover:text-purple-800 transition-colors"
				>
					← Back to Query
				</Link>


				{error && <ErrorMessage message={error} />}

				<h2 className="text-3xl font-bold text-center mb-8 text-purple-800">
					Upload Your Notes 💝
				</h2>

				{!uploadType && (

					<motion.button
						whileHover={{ scale: 1.05 }}
						whileTap={{ scale: 0.95 }}
						className="p-8 rounded-xl bg-white shadow-lg hover:shadow-xl transition-all"
						onClick={() => setUploadType('image')}
					>
						<FiImage className="w-12 h-12 mx-auto mb-4 text-purple-600" />
						<h3 className="text-xl font-semibold text-center">Upload Images</h3>
						{/*
							<div className="grid grid-cols-2 gap-6">
						<motion.button
							whileHover={{ scale: 1.05 }}
							whileTap={{ scale: 0.95 }}
							className="p-8 rounded-xl bg-white shadow-lg hover:shadow-xl transition-all"
							onClick={() => setUploadType('text')}
						>
							<FiFileText className="w-12 h-12 mx-auto mb-4 text-purple-600" />
							<h3 className="text-xl font-semibold text-center">Upload Text</h3>
						</motion.button>
									</div>
						*/}
					</motion.button>


				)}

				{uploadType === 'image' && step <= 3 && (
					<div className="bg-white rounded-xl p-8 shadow-lg">
						{step === 1 && (
							<div
								{...getRootProps()}
								className={`border-2 border-dashed border-purple-300 rounded-xl p-8 text-center cursor-pointer
                  ${isDragActive ? 'bg-purple-50' : 'bg-white'}`}
							>
								<input {...getInputProps()} />
								<FiUploadCloud className="w-16 h-16 mx-auto mb-4 text-purple-400" />
								<p className="text-gray-600">Drag & drop images here, or click to select files</p>
								{images.length > 0 && (
									<div className="mt-4">
										<button
											onClick={(e) => {
												e.stopPropagation();
												handleOCRProcess();
											}}
											disabled={isProcessing}
											className={`bg-purple-600 text-white px-6 py-2 rounded-lg hover:bg-purple-700 
                            ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
										>
											{isProcessing ? 'Processing...' : `Process ${images.length} images`}
										</button>
									</div>
								)}
							</div>
						)}
						{step === 3 && (
							<div className="space-y-8">
								<h3 className="text-xl font-semibold mb-6">Confirm Extracted Text</h3>
								{ocrData.map((item, index) => (
									<div key={index} className="bg-white p-8 rounded-xl shadow-md hover:shadow-xl transition-shadow">
										<div className="flex flex-col lg:flex-row gap-8">
											{/* Image Section */}
											<div className="w-full lg:w-1/3 flex-shrink-0">
												<div
													className="cursor-pointer relative group"
													onClick={() => setSelectedImage(item.image_src)}
												>
													<img
														src={item.image_src}
														alt={`Uploaded image ${index + 1}`}
														className="w-full h-auto rounded-lg object-cover transition-transform duration-200 group-hover:scale-[1.02]"
													/>
													<div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-all duration-200 rounded-lg flex items-center justify-center">
														<span className="text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200">
															Click to expand
														</span>
													</div>
												</div>
											</div>

											{/* Text Section */}
											<div className="w-full lg:w-2/3">
												<div className="space-y-3">
													<div className="flex justify-between items-center">
														<label className="text-sm font-medium text-gray-700">
															Transcript for Image {index + 1}
														</label>
														<button
															onClick={() => handleRecaption(index)}
															disabled={isProcessing}
															className={`flex items-center gap-2 px-4 py-2 rounded-lg text-sm
                    ${isProcessing
																	? 'bg-gray-200 text-gray-500 cursor-not-allowed'
																	: 'bg-purple-100 text-purple-600 hover:bg-purple-200'} 
                    transition-all duration-200`}
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																className="h-4 w-4"
																fill="none"
																viewBox="0 0 24 24"
																stroke="currentColor"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	strokeWidth={2}
																	d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
																/>
															</svg>
															{isProcessing ? 'Processing...' : 'Recaption'}
														</button>
													</div>
													<div className="relative">
														<textarea
															value={ocrData[index].content}
															onChange={(e) => {
																const newTexts = [...ocrData];
																newTexts[index].content = e.target.value;
																setOcrData(newTexts);
															}}
															className="w-full p-4 border rounded-lg focus:ring-2 focus:ring-purple-500 
                    focus:border-transparent transition-all duration-200 min-h-[300px] 
                    resize-y text-base leading-relaxed bg-gray-50"
															placeholder="Extracted text..."
															style={{
																lineHeight: '1.8',
																letterSpacing: '0.01em',
															}}
														/>
														<div className="absolute bottom-3 right-3 text-sm text-gray-500">
															{ocrData[index].content.length} characters
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}

								<div className="flex justify-end mt-8 sticky bottom-4">
									<button
										onClick={handleTextConfirmation}
										disabled={isProcessing}
										className={`bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 
          transition-colors duration-200 flex items-center gap-2 font-medium shadow-lg
          hover:shadow-xl ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
									>
										<span>{isProcessing ? 'Processing...' : 'Confirm Texts'}</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-5 w-5"
											viewBox="0 0 20 20"
											fill="currentColor"
										>
											<path
												fillRule="evenodd"
												d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
							</div>
						)}
					</div>
				)}

				{uploadType === 'text' && (
					<div className="bg-white rounded-xl p-8 shadow-lg">
						<textarea
							value={textInput}
							onChange={(e) => setTextInput(e.target.value)}
							className="w-full p-4 border rounded-lg mb-4"
							rows={8}
							placeholder="Enter your text here..."
						/>
						<button
							onClick={() => {
								setOcrData([{ content: textInput, image_src: '' }]);
								setStep(5);
							}}
							disabled={isProcessing}
							className={`bg-purple-600 text-white px-6 py-2 rounded-lg hover:bg-purple-700 flex items-center gap-2
      ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
						>
							<span>{isProcessing ? 'Processing...' : 'Process Text'}</span>
							{isProcessing && (
								<svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
								</svg>
							)}
						</button>
					</div>
				)}

				{step === 5 && chunks && (
					<div className="bg-white rounded-xl p-4 md:p-8 shadow-lg">
						<h3 className="text-2xl font-semibold mb-6 text-gray-800">Confirm Final Chunks</h3>

						{/* Image Slideshow */}
						{chunks.image_sources && chunks.image_sources.length > 0 && (
							<div className="mb-8 relative h-96 bg-gray-100 rounded-lg overflow-hidden">
								<div className="absolute inset-0">
									<img
										src={chunks.image_sources[currentImageIndex]}
										alt={`Slide ${currentImageIndex + 1}`}
										className="w-full h-full object-contain"
									/>
								</div>

								{/* Navigation Arrows */}
								{chunks.image_sources.length > 1 && (
									<>
										<button
											onClick={() => setCurrentImageIndex(prev =>
												prev === 0 ? chunks.image_sources.length - 1 : prev - 1
											)}
											className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full hover:bg-black/70"
										>
											<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
											</svg>
										</button>
										<button
											onClick={() => setCurrentImageIndex(prev =>
												prev === chunks.image_sources.length - 1 ? 0 : prev + 1
											)}
											className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full hover:bg-black/70"
										>
											<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
											</svg>
										</button>
									</>
								)}

								{/* Slide Indicators */}
								{chunks.image_sources.length > 1 && (
									<div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
										{chunks.image_sources.map((_: any, index: number) => (
											<button
												key={index}
												onClick={() => setCurrentImageIndex(index)}
												className={`w-2 h-2 rounded-full transition-all ${currentImageIndex === index ? 'bg-white w-4' : 'bg-white/50'
													}`}
											/>
										))}
									</div>
								)}
							</div>
						)}

						{/* Chunks Section */}
						<div className="space-y-6">
							{chunks.chunks && chunks.chunks.map((chunk: any, index: number) => (
								<div key={index} className="bg-gray-50 rounded-lg p-6 shadow-sm">
									<div className="mb-4">
										<h4 className="text-lg font-medium text-gray-700 mb-2">Chunk {index + 1}</h4>
										<textarea
											value={chunk.content}
											onChange={(e) => {
												const newChunks = { ...chunks };
												newChunks.chunks[index].content = e.target.value;
												setChunks(newChunks);
											}}
											className="w-full p-4 border rounded-lg focus:ring-2 focus:ring-purple-500 
          focus:border-transparent resize-y bg-white text-base leading-relaxed min-h-[250px]"
											placeholder="Enter chunk content..."
											style={{
												lineHeight: '1.8',
												letterSpacing: '0.01em',
											}}
										/>
									</div>
								</div>
							))}
						</div>

						{/* Submit Button */}
						<div className="mt-8 flex justify-end">
							<button
								onClick={handleFinalSubmission}
								disabled={isProcessing}
								className={`bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 
                    transition-all duration-200 shadow-md hover:shadow-lg transform hover:-translate-y-0.5
                    flex items-center gap-2 ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
							>
								<span>{isProcessing ? 'Processing...' : 'Submit All'}</span>
								{isProcessing ? (
									<svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
										<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
								) : (
									<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
										<path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
									</svg>
								)}
							</button>
						</div>
					</div>
				)}
			</div>
			<ImageModal selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
		</div>
	);
}

export default Upload;